
























import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import {ModelDataInterface} from '@/types/userDataInterface';
import {PhotoInterface} from '@/types/photoInterface';
import {PhotosSearchParamsInterface} from '@/types/PhotosSearchParamsInterface';
import {vxm} from '@/store';
import Loader from '@/components/Loader.vue';
import LoginOrRegisterModal from '@/components/modals/LoginOrRegisterModal.vue';
import PostCardNew from '@/components/PostCardNew.vue';

@Component({
  components: {
    LoginOrRegisterModal,
    Loader,
    PostCardNew,
  },
})
export default class ModelPics extends Vue {
  @Prop() readonly model!: ModelDataInterface;
  @Prop({default: false}) readonly isSubscribed!: boolean;
  viewType = 'grid';
  photos: PhotoInterface[] = [];
  isRequestSend = false;
  totalPhotos = 0;
  params: PhotosSearchParamsInterface = {
    page: 1,
    take: 15,
    performerId: '',
    status: 'active',
    isForSale: this.isPicsPlus,
    isPurchased: undefined,
  };
  showMediaDetails = false;
  showSubscriptionModal = false;
  selectedImage: PhotoInterface | null = null;
  showLoginModal = false;

  mounted() {
    if (this.model) {
      this.params.performerId = this.model._id;
      const storage = JSON.parse(localStorage.getItem('fan') as string);
      if (
        storage &&
        storage[this.$route.name as string] &&
        this.params.performerId === storage[this.$route.name as string].performerId
      ) {
        this.totalPhotos = storage[this.$route.name as string].count;
        this.photos.push(...storage[this.$route.name as string].items);
        this.getPhotos(true, true);
      } else {
        this.getPhotos(true);
      }
    }
    this.$emit('viewChanged', this.viewType);
    // window.addEventListener('scroll', this.handleScroll);
  }

  destroyed() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll() {
    // const bottomOfWindow = window.innerHeight + window.pageYOffset >= document.body.offsetHeight - 2;
    if (!this.isRequestSend && this.totalPhotos > this.photos.length) {
      this.getPhotos();
    }
  }

  @Watch('isSubscribed')
  onSubscribedChange(val: boolean) {
    if (val && this.model) {
      this.params.user = this.model._id;
      this.getPhotos();
    }
  }

  @Watch('model')
  @Watch('$route')
  onChange() {
    this.params.isForSale = this.isPicsPlus;
    this.params.isPurchased = undefined;
    this.params.page = 1;
    this.photos = [];
    this.params.performerId = this.model._id;
    if (!this.isRequestSend) {
      const storage = JSON.parse(localStorage.getItem('fan') as string);
      if (
        storage &&
        storage[this.$route.name as string] &&
        this.params.performerId === storage[this.$route.name as string].performerId
      ) {
        this.totalPhotos = storage[this.$route.name as string].count;
        this.photos.push(...storage[this.$route.name as string].items);
        this.getPhotos(true, true);
      } else {
        this.getPhotos(true);
      }
    }
  }

  get isPicsPlus(): boolean {
    return this.$route.name === 'model-pics-plus';
  }

  get isPerformer(): boolean {
    return vxm.user.data.role === 'performer';
  }

  get isMy(): boolean {
    if (this.isPerformer) {
      return this.model._id ? this.model._id === vxm.user.data._id : true;
    }
    return false;
  }

  get paymentSuccessCount() {
    return this.$store.state.paymentSuccessCount;
  }

  @Watch('paymentSuccessCount') onPaymentSuccess() {
    const take = (this.params.page as number) * (this.params.take as number);
    const isFirst = false;
    const backgroundUpdate = false;
    if (!this.isPerformer || this.isMy) {
      this.isRequestSend = !backgroundUpdate;

      const apiName = this.isPerformer ? vxm.model.getMyAlbums : vxm.fan.getPhotosSearch;
      apiName({...this.params, page: 1, take: take})
        .then((res) => {
          this.totalPhotos = res.data.count;
          if (isFirst) {
            this.photos = res.data.items;
            this.updLocalStorage(res.data);
          } else {
            this.photos = res.data.items;
          }
        })
        .catch((error) => {
          return error;
        })
        .then(() => {
          this.isRequestSend = false;
          if (this.isSubscribed && this.$route.query.id) {
            this.showMediaFromQuery();
          }
        });
    }
  }

  getPhotos(isFirst = false, backgroundUpdate = false) {
    if (!this.isPerformer || this.isMy) {
      this.isRequestSend = !backgroundUpdate;

      const apiName = this.isPerformer ? vxm.model.getMyAlbums : vxm.fan.getPhotosSearch;
      apiName(this.params)
        .then((res) => {
          this.totalPhotos = res.data.count;
          if (isFirst) {
            this.photos = res.data.items;
            this.updLocalStorage(res.data);
          } else {
            this.photos.push(...res.data.items);
          }
          (this.params.page as number)++;
        })
        .catch((error) => {
          return error;
        })
        .then(() => {
          this.isRequestSend = false;
          if (this.isSubscribed && this.$route.query.id) {
            this.showMediaFromQuery();
          }
        });
    }
  }

  updLocalStorage(data) {
    let storage = JSON.parse(localStorage.getItem('fan') as string);
    if (!storage) {
      storage = {};
      storage[this.$route.name as string] = {items: [], count: 0, performerId: ''};
    }
    storage[this.$route.name as string] = {
      performerId: this.params.performerId,
      items: data.items,
      count: data.count,
    };
    localStorage.setItem('fan', JSON.stringify(storage));
  }

  showMediaFromQuery() {
    this.selectedImage = this.photos.find((element) => element._id === this.$route.query.id) || null;
    if (this.selectedImage) {
      this.showMediaDetails = true;
    }
  }

  changePurchaseFilter(newFilter: boolean) {
    this.params.isPurchased = newFilter ? newFilter : undefined;
    this.params.page = 1;
    this.photos = [];
    this.getPhotos();
  }
}
